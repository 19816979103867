<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo"
              to="/">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Convert Web To App! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account to start building your app
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              autocomplete="on"
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  id="email"
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forget-passoword'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isloading"
                @click="validationForm"
              >
                <b-spinner
                  v-if="isloading"
                  small
                />
                <span> Sign in</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BSpinner, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BSpinner,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isloading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.isloading = true
          this.$store.dispatch('auth/login', {
            email: this.userEmail,
            password: this.password,
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login Successful',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.isloading = false
          })
            .catch(err => {
              console.log(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Incorrect Email or Password',
                  icon: 'FrownIcon',
                  variant: 'danger',
                },
              })
              this.isloading = false
            })
        }
      })
    },
  },
  metaInfo: {
    // Children can override the title.
    title: 'Login',
    titleTemplate: '%s | Web To App Convert',
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Convert any website to Android Application for free. Login to your dashboard' },
      { name: 'keywords', content: 'Web To App, Convert Web To Android, Android, Website to App, Wordpress to Android, App, Free App Maker, Free, Convert, Website To app, Login, Sign In' },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'Login | Web To App Convert' },
      { property: 'og:site_name', content: 'Web To App Convert' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.webtoappconvert.com/login' },
      { property: 'og:image', content: 'https://www.webtoappconvert.com/img/logo.png' },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'Convert any website to Android Application for free. Login to your dashboard' },
      // Twitter card
      { name: 'twitter:card', content: 'Convert any website to Android Application for free.' },
      { name: 'twitter:site', content: 'https://www.webtoappconvert.com/login' },
      { name: 'twitter:title', content: 'Login | Web To App Convert' },
      { name: 'twitter:description', content: 'Convert any website to Android Application for free. Login to your dashboard' },
      // Your twitter handle, if you have one.
      // { name: 'twitter:creator', content: '@alligatorio' },
      { name: 'twitter:image:src', content: 'https://www.webtoappconvert.com/img/logo.png' },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Login | Web To App Convert' },
      { itemprop: 'description', content: 'Convert any website to Android Application for free. Login to your dashboard' },
      { itemprop: 'image', content: 'https://www.webtoappconvert.com/img/logo.png' },
    ],
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
